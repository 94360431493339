import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { AuthService } from '../../../../app/modules/auth';
import { environment } from '../../../../environments/environment';
import { catchError, filter, finalize, retry, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private authService: AuthService;
    private authData: any;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    isRefreshingToken = false;
    currentAccessToken: string = null;

    constructor(private injector: Injector, private router: Router) { }

    // Intercept every HTTP call
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Check if we need additional token logic or not
        if (this.isInBlockedList(request.url)) {
            return next.handle(request);
        } else {
            this.authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken));

            if (!(this.authData == null || this.authData == undefined)) {
                this.currentAccessToken = this.authData.authToken;
            }

            return next.handle(this.addToken(request)).pipe(
                catchError(err => {
                    if (err instanceof HttpErrorResponse) {

                        console.log(err);

                        this.authService = this.injector.get(AuthService);

                        switch (err.status) {
                            case 400:
                                return this.handle400Error(err);
                            case 401:
                                return this.handle401Error(request, next);
                            case 403:
                                return this.handle401Error(request, next);
                            default:
                                return throwError(() => err);
                        }
                    } else {
                        return throwError(() => err);
                    }
                })
            );
        }
    }

    // Filter out URLs where you don't want to add the token!
    private isInBlockedList(url: string): Boolean {
        // Example: Filter out our login and logout API call
        // url.endsWith("/auth/login") || url.endsWith("/auth/logout") || url.endsWith('/auth/account/setup') || url.endsWith("/auth/forgot-password") || url.endsWith("/auth/new-password"
        if (url == `${environment.apiUrl}/auth/login` ||
            url == `${environment.apiUrl}/auth/logout` ||
            url == `${environment.apiUrl}/auth/account/setup` ||
            url == `${environment.apiUrl}/auth/forgot-password` ||
            url == `${environment.apiUrl}/auth/new-password` ||
            url == `${environment.apiUrl}/auth/refresh` // ||          url.includes(`billings/invoices/download`)
        ) {
            return true;
        } else {
            return false;
        }
    }

    // Add our current access token from the service if present
    private addToken(req: HttpRequest<any>) {
        if (this.currentAccessToken) {
            return req.clone({
                headers: new HttpHeaders({
                    Authorization: `Bearer ${this.currentAccessToken}`
                })
            });
        } else {
            return req;
        }
    }

    // We are not just authorized, we couldn't refresh token
    // or something else along the caching went wrong!
    private async handle400Error(err) {
        // Potentially check the exact error reason for the 400
        // then log out the user automatically
        /*
        const toast = await this.toastCtrl.create({
            message: 'Logged out due to authentication mismatch',
            duration: 2000
        });
        toast.present();
        */
        // this.authService.logout();
        return of(null);
    }


    // Indicates our access token is invalid, try to load a new one
    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Check if another call is already using the refresh logic
        if (!this.isRefreshingToken) {

            // Set to null so other requests will wait
            // until we got a new token!
            this.tokenSubject.next(null);
            this.isRefreshingToken = true;
            this.currentAccessToken = null;

            // First, get a new access token
            return this.authService.refreshToken().pipe(
                switchMap((token: any) => {
                    if (token) {
                        // Store the new token
                        //const accessToken = token.accessToken;
                        this.authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken));

                        if (!(this.authData == null || this.authData == undefined)) {
                            this.currentAccessToken = this.authData.authToken;
                            return next.handle(this.addToken(request));
                        }

                        return of(null);

                        /*
                        return this.authService.storeAccessToken(accessToken).pipe(
                            switchMap(_ => {
                                // Use the subject so other calls can continue with the new token
                                this.tokenSubject.next(accessToken);

                                // Perform the initial request again with the new token
                                return next.handle(this.addToken(request));
                            })
                        );
                        */
                    } else {
                        // No new token or other problem occurred
                        this.authService.logout();
                        return of(null);
                    }
                }),
                catchError((err) => {
                    //this.isRefreshing = false;
                    console.log(err);

                    this.authService.logout();
                    //this.tokenService.signOut();

                    // return throwError(err);
                    // return of(null);
                    return throwError(() => err);
                }),
                finalize(() => {
                    console.log("finalizie");

                    // Unblock the token reload logic when everything is done
                    this.isRefreshingToken = false;
                })
            );
        } else {
            // "Queue" other calls while we load a new token
            return this.tokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(token => {
                    // Perform the request again now that we got a new token!
                    return next.handle(this.addToken(request));
                })
            );
        }
    }
}
