import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/_services/auth.service';
// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
// #fake-start#
import { HttpInterceptorService } from './_metronic/core/services/http-interceptor.service';
import { AppConfigModule } from './app.config.module';

// #fake-end#

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve, rejected) => {
      authService.getUserByToken().subscribe({
        next: data => {
          resolve(data);
        },
        error: err => {
          rejected(err);
        }
      });
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    // PermissionDirective,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    TranslateModule.forRoot(),
    HighlightModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    AppConfigModule,
    NgbModule], providers: [
      //AuthService,
      {
        provide: APP_INITIALIZER,
        useFactory: appInitializer,
        multi: true,
        deps: [AuthService],
      },
      {
        provide: HIGHLIGHT_OPTIONS,
        useValue: {
          coreLibraryLoader: () => import('highlight.js/lib/core'),
          languages: {
            xml: () => import('highlight.js/lib/languages/xml'),
            typescript: () => import('highlight.js/lib/languages/typescript'),
            scss: () => import('highlight.js/lib/languages/scss'),
            json: () => import('highlight.js/lib/languages/json')
          },
        },
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpInterceptorService,
        multi: true,
      },
      provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule { }
